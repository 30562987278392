<template>
	<div style="margin-bottom: 20px;" align="center">
		<ins class="adsbygoogle" style="display:block;" data-ad-client="ca-pub-8147879111198627"
			data-ad-slot="4026586674" data-ad-format="auto" data-full-width-responsive="true"></ins>
	</div>
	<div class="card grid" style="justify-content: space-around;">
		<!-- <div class="card col-12 md:col-12"> -->
		<dvi class="col-12 md:col-7">
			<iframe ref='pdfjs_viewer_1' name="pdf_container" id="pdf_container"
				:src="'pdfjs-develop/web/viewer.html?file='+pdf_path" frameborder="0" width="100%"
				:height="pdf_height"></iframe>
		</dvi>
		<div class="col-12 md:col-3" style="margin-top: -10px;" align="center">
			<div class="p-3">
				<AdminInfo></AdminInfo>
			</div>
			<div class="p-3">
				<Payme></Payme>
			</div>
		</div>
		<div class="col-12 md:col-2" style="margin-top: 10px;" align="center">
			<ins class="adsbygoogle" style="display:inline-block;width:160px;height:600px"
				data-ad-client="ca-pub-8147879111198627" data-ad-slot="2871419116"></ins>
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	// import GoogleAds from './GoogleAds.vue';
	import AdminInfo from './AdminInfo.vue';
	import Payme from './Payme.vue';
	export default {
		name: '',
		components: {
			// 'GoogleAds': GoogleAds,
			'AdminInfo':AdminInfo,
			'Payme':Payme,
		},
		data() {
			return {
				pdf_path: 'example.pdf',
				pdf_height: "720px",
			}
		},
		// networkService: null,
		created() {
			//实例化NetWorkService对象
			// this.networkService = new NetWorkService();
		},
		mounted() {
			window.addAds();
			window.addAds();
			// let vm = this;
			// vm.resizeWin();
			// //屏幕变化时
			// window.onresize = () => {
			// 	return (() => {
			// 		vm.resizeWin();
			// 	})();
			// };
		},
		methods: {
			//屏幕变化调整样式
			resizeWin() {
				// this.pdf_width = document.documentElement.scrollWidth-400+'px';
				var windowHeight = document.documentElement.clientHeight;
				if (windowHeight > 830) {
					this.pdf_height = '720px';
				} else {
					this.pdf_height = windowHeight - 110 + 'px';
				}
				// console.log('高度',this.pdf_height);
			},
		},
	}
</script>

<style scoped lang="scss">
</style>
